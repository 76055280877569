import {
  BrowserRouter as Router,
  Routes,
  Route,
  // Navigate,
} from "react-router-dom";
import './App.css';
import Home from "./pages/home";
import Assignments from "./pages/assignments";

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/assignments" element={<Assignments />} />
      </Routes>
    </Router>
  )
}

export default App;
