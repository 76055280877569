import React from 'react';
import './footer.css';

function Footer() {
    return (
        <div className='footer'>
            <p>Discord: <a target="_blank" rel="noopener noreferrer" href='https://discordapp.com/users/339917839483797504'>asura.akuma</a></p>
            <p>Email: <a target="_blank" rel="noopener noreferrer" href="mailto:aigaigkc@gmail.com">aigaigkc@gmail.com</a></p>
            <p>LinkedIn: <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/adam-gardner-24b01b1a7/">Click Here</a></p>
            <p>GitHub: <a target="_blank" rel="noopener noreferrer" href="https://github.com/AsuraAkuma">AsuraAkuma</a></p>
        </div>
    )
}

export default Footer;