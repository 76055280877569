import React from 'react';
import './header.css'
const Header = () => {
    function handleClick(e) {
        e.preventDefault();
        if (e.target.id === "mywork-button") {
            window.location.pathname = "/assignments";
        } else {
            if (window.location.pathname === "/assignments") {
                window.location.pathname = "/";
            } else {
                const homeElement = document.getElementById('home');
                const target = document.getElementById(`section-${e.target.id.split("-")[0]}`);
                target.scrollIntoView({ behavior: "smooth", block: "start", inline: "start" });
                setTimeout(() => {
                    homeElement.scroll({ top: homeElement.scrollTop - 150, behavior: "smooth" })
                }, 1000);
            }
        }
    }
    window.addEventListener('load', () => {
        if (window.location.pathname === "/") {
            let sticky = false;
            const homeElement = document.getElementById('home');
            const header = document.getElementById("header");
            let currentButton = document.getElementById('home-button');
            // let currentSection = document.getElementById('section-home');
            homeElement.addEventListener('scroll', () => {
                // stick header
                if (homeElement.scrollTop >= 916) {
                    if (sticky === false) {
                        header.classList.add("sticky");
                        sticky = true;
                    }
                } else {
                    if (sticky === true) {
                        header.classList.remove("sticky");
                        sticky = false;
                    }
                }
                // header underline
                const pages = ['home', 'aboutme', 'myjourney', 'mygoals', 'myeducation'];
                const pageNum = Math.floor(homeElement.scrollTop / homeElement.clientHeight);
                const button = document.getElementById(`${pages[(pageNum >= pages.length) ? pages.length - 1 : pageNum]}-button`);
                button.classList.add("header-underlined");
                if (currentButton !== button) {
                    currentButton.classList.remove("header-underlined");
                    currentButton = button;
                }
                // Animate stuff coming up
                const elementIsVisibleInViewport = (el, partiallyVisible = false) => {
                    if (el) {
                        const { top, left, bottom, right } = el.getBoundingClientRect();
                        const { innerHeight, innerWidth } = window;
                        return partiallyVisible
                            ? ((top > 0 && top < innerHeight) ||
                                (bottom > 0 && bottom < innerHeight)) &&
                            ((left > 0 && left < innerWidth) || (right > 0 && right < innerWidth))
                            : top >= 0 && left >= 0 && bottom <= innerHeight && right <= innerWidth;
                    }
                };
                for (let i = 0; i < pages.length; i++) {
                    const tag = document.getElementById(`section-${pages[i]}`);
                    const result = elementIsVisibleInViewport(tag, true); // true - (partially visible)
                    if (tag) {
                        if (result === true) {
                            tag.classList.add("visible");
                            tag.classList.remove("invisible");
                        } else {
                            tag.classList.add("invisible");
                            tag.classList.remove("visible");
                        }

                    }

                }
            })
        }
    })
    if (window.location.pathname === "/") {
        return (
            <div className={`header`} id='header'>
                <div className='header-section header-section-name'>
                    <p className='header-section-text'>Adam Gardner</p>
                </div>
                <div className='header-section header-section-buttons'>
                    <button className='header-section-button' id='home-button' onClick={handleClick}>{"< Home />"}</button>
                    <button className='header-section-button' id='aboutme-button' onClick={handleClick}>{"< About Me />"}</button>
                    <button className='header-section-button' id='myjourney-button' onClick={handleClick}>{"< My Journey />"}</button>
                    <button className='header-section-button' id='mygoals-button' onClick={handleClick}>{"< My Goals />"}</button>
                    <button className='header-section-button' id='myeducation-button' onClick={handleClick}>{"< My Education />"}</button>
                    <button className='header-section-button' id='mywork-button' onClick={handleClick}>{"< My Work />"}</button>
                </div>
            </div>
        )
    } else if (window.location.pathname === "/assignments") {
        return (
            <div className={`header`} id='header'>
                <div className='header-section header-section-name'>
                    <p className='header-section-text'>Adam Gardner</p>
                </div>
                <div className='header-section header-section-buttons'>
                    <button className='header-section-button ' id='home-button' onClick={handleClick}>{"< Home />"}</button>
                    <button className='header-section-button header-underlined' id='mywork-button' onClick={handleClick}>{"< My Work />"}</button>
                </div>
            </div>
        )

    } else {
        return (
            <div className={`header`} id='header'>
                {/* <div className='header-section header-section-name'>
                    <p className='header-section-text'>Adam Gardner</p>
                </div>
                <div className='header-section header-section-buttons'>
                    <button className='header-section-button' id='home-button' onClick={handleClick}>{"< Home />"}</button>
                    <button className='header-section-button' id='aboutme-button' onClick={handleClick}>{"< About Me />"}</button>
                    <button className='header-section-button' id='myjourney-button' onClick={handleClick}>{"< My Journey />"}</button>
                    <button className='header-section-button' id='mygoals-button' onClick={handleClick}>{"< My Goals />"}</button>
                    <button className='header-section-button' id='myeducation-button' onClick={handleClick}>{"< My Education />"}</button>
                    <button className='header-section-button' id='mywork-button' onClick={handleClick}>{"< My Work />"}</button>
                </div> */}
            </div>
        )
    }
}

export default Header;